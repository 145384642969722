import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наши услуги | Изучите предложения Час-Пик Агентство недвижимости
			</title>
			<meta name={"description"} content={"Услуги по строительству дома вашей мечты"} />
			<meta property={"og:title"} content={"Наши услуги | Изучите предложения Час-Пик Агентство недвижимости"} />
			<meta property={"og:description"} content={"Услуги по строительству дома вашей мечты"} />
			<meta property={"og:image"} content={"https://quantixarix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quantixarix.com/img/942089.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quantixarix.com/img/942089.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quantixarix.com/img/942089.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quantixarix.com/img/942089.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quantixarix.com/img/942089.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quantixarix.com/img/942089.png"} />
			<meta name={"msapplication-TileColor"} content={"https://quantixarix.com/img/942089.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Час-Пик Агентство недвижимости Services
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
			Наши услуги
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			В компании Час-Пик Агентство недвижимости мы предоставляем полный спектр услуг, направленных на удовлетворение всех ваших потребностей в сфере недвижимости. Наша цель - предложить вам исключительное обслуживание, сопровождая вас на каждом этапе процесса. Узнайте, что Час-Пик Агентство недвижимости может сделать для вас.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Покупка жилья
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Поиск недвижимости: Мы поможем вам найти идеальную недвижимость, соответствующую вашим критериям.
					<br/><br/>
Анализ рынка: Предоставление вам актуальных тенденций и информации о рынке.
					<br/><br/>
Экскурсии по дому: Организация посещения интересующих вас объектов недвижимости.
					<br/><br/>
Переговоры: Квалифицированное ведение переговоров, чтобы обеспечить вам наилучшую сделку.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Продажа дома
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Оценка недвижимости: Точная оценка для определения наилучшей цены за ваш дом.
Маркетинг: Комплексные маркетинговые стратегии для привлечения потенциальных покупателей.
Советы по стейджингу: Советы и услуги, чтобы сделать ваш дом более привлекательным.
Дни открытых дверей: Организация и проведение дней открытых дверей для демонстрации вашей недвижимости.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Услуги по аренде
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Объявления об аренде: Доступ к широкому спектру объектов аренды.
Отбор арендаторов: Тщательная проверка для поиска надежных арендаторов.
Договоры аренды: Помощь в составлении и проверке договоров аренды.
Управление недвижимостью: Постоянная поддержка в управлении вашей арендной недвижимостью.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 50px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 30px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Услуги по переезду
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="30px 30px 30px 30px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Планирование переезда
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Индивидуальные планы для облегчения вашего переезда.
					</Text>
				</Box>
				<Box
					padding="30px 30px 30px 30px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Ориентирование на местности
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Экскурсии и информация о новом районе.
					</Text>
				</Box>
				<Box
					padding="30px 30px 30px 30px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Временное жилье
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Поиск краткосрочного жилья на время переходного периода
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="50%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Путешествие к дому вашей мечты начинается здесь
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Час-Пик Агентство недвижимости стремится предоставить вам самый высокий уровень обслуживания. Позвольте нам помочь вам достичь ваших целей в сфере недвижимости. Свяжитесь с нами сегодня, чтобы начать свое путешествие с нами.
				</Text>
				<Link
					href="/contact-us"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Контакты
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://quantixarix.com/img/2.jpg"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});